import React, { useState, useEffect } from "react";
import whatsapp from "assets/icons/whatsappFill.svg";

function Whatsapp() {
  const [bottomPosition, setBottomPosition] = useState("bottom-6");
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.scrollY;

      if (documentHeight - (scrollPosition + windowHeight) < 200) {
        setIsTransitioning(true);
        setBottomPosition("hidden md:bottom-36");
      } else {
        setIsTransitioning(false);
        setBottomPosition("bottom-6");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <a
      className={`fixed z-50 md:block ${bottomPosition} right-4 rounded-full border-2 border-white bg-[#1fac52] p-4 px-8 text-xl font-medium uppercase text-white shadow-xl transition-all duration-300 md:text-2xl ${
        isTransitioning ? "ease-in-out" : ""
      }`}
      href="https://docs.google.com/forms/d/e/1FAIpQLSenTLRltBSYYK6-7Ssnt0UeCkrYNcYRBDXwAlAsZ5Lpso2qWw/viewform"
      target="_blank"
    >
      Cadastre-se aqui
    </a>
  );
}

export default Whatsapp;
