import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "assets/icons/logo.svg";
import menu from "assets/icons/menu.svg";
import Navigation from "components/Navigation";

function Header() {
  const [textIndex, setTextIndex] = useState(0);
  const [showNavigation, setShowNavigation] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const texts = ["Seja uma revendedora Fani"];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [texts.length]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (Math.abs(currentScrollY - lastScrollY) > 200) {
        setIsHeaderVisible(currentScrollY < lastScrollY);
        setLastScrollY(currentScrollY);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const handleClick = () => setShowNavigation(!showNavigation);

  return (
    <>
      <header
        className={`bg-filter flex w-full flex-col bg-white bg-cover bg-no-repeat shadow-md transition-all duration-300 md:fixed md:top-0 md:z-30 ${
          isHeaderVisible ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <nav className="flex items-center justify-between bg-opacity-5 p-2 text-gray-900 md:px-12">
          <ul className="hidden items-center gap-12 md:flex">
            <li>
              <a
                href="#quem-somos"
                className="text-lg uppercase transition-colors duration-500 hover:text-slate-300"
              >
                Quem somos
              </a>
            </li>
            <li>
              <a
                className="text-lg uppercase transition-colors duration-500 hover:text-slate-300"
                href="#programa-de-revenda"
              >
                Programa de revenda
              </a>
            </li>
            <li>
              <a
                href="#nossa-colecao"
                className="text-lg uppercase transition-colors duration-500 hover:text-slate-300"
              >
                Nossa coleção
              </a>
            </li>
          </ul>

          <Link top="/">
            <figure className="flex items-center justify-start hover:opacity-75 md:justify-center">
              <img
                src={logo}
                alt="Logo da Fani Semijoias"
                loading="lazy"
                className="w-16 md:w-24"
              />
              <figcaption className="text-xl md:hidden">
                Fani Semijoias
              </figcaption>
            </figure>
          </Link>

          <ul className="hidden items-center gap-10 md:flex">
            <li>
              <a
                href="#historias-de-sucesso"
                className="text-lg uppercase transition-colors duration-500 hover:text-slate-300"
              >
                Histórias de sucesso
              </a>
            </li>
            <li>
              <a
                className="text-lg uppercase transition-colors duration-500 hover:text-slate-300"
                href="#perguntas-frequentes"
              >
                Perguntas Frequentes
              </a>
            </li>
            <li>
              <a
                className="text-lg uppercase transition-colors duration-500 hover:text-slate-300"
                href="#contato"
              >
                Contato
              </a>
            </li>
          </ul>

          <button className="md:hidden" onClick={handleClick}>
            <img src={menu} alt="Menu" className="w-10" />
          </button>
        </nav>

        {/* <section className='flex w-full justify-center overflow-hidden bg-black p-2'>
          <a href='##' className='animate-carousel w-full text-center uppercase text-white'>
            {texts[textIndex]}
          </a>
        </section> */}
      </header>

      <Navigation
        isOpen={showNavigation}
        onClose={() => setShowNavigation(false)}
      />
    </>
  );
}

export default Header;
