import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Navigation({ isOpen, onClose }) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleaClick = () => {
    handleClose();
  };

  const overlayClass = isOpen ? "opacity-90" : "opacity-0 pointer-events-none";
  const translateClass = isOpen ? "translate-x-0" : "translate-x-full";

  return (
    <section id="nav" className="transition-all">
      <div
        className={`fixed inset-0 z-30 bg-black ${overlayClass} backdrop-blur-2xl backdrop-filter duration-500 ease-in-out`}
        onClick={handleClose}
      />
      <nav
        className={`fixed inset-y-0 right-0 z-40 flex w-10/12 flex-col justify-center gap-8 bg-white p-8 shadow-lg backdrop-blur-2xl duration-1000 ease-in-out md:w-1/3 ${translateClass}`}
      >
        <ul className="flex flex-col gap-4">
          <li>
            <Link
              to="/"
              onClick={handleaClick}
              className="cursor-pointer text-3xl font-semibold text-black hover:text-pink hover:underline"
            >
              Início
            </Link>
          </li>
          <li>
            <a
              href="#quem-somos"
              onClick={handleaClick}
              className="cursor-pointer text-3xl font-semibold text-black hover:text-pink hover:underline"
            >
              Quem Somos
            </a>
          </li>
          <li>
            <a
              href="#programa-de-revenda"
              onClick={handleaClick}
              className="cursor-pointer text-3xl font-semibold text-black hover:text-pink hover:underline"
            >
              Programa de revenda
            </a>
          </li>

          <li>
            <a
              href="nossa-colecao"
              onClick={handleaClick}
              className="cursor-pointer text-3xl font-semibold text-black hover:text-pink hover:underline"
            >
              Nossa Coleção
            </a>
          </li>
          <li>
            <a
              href="#historias-de-sucesso"
              onClick={handleaClick}
              className="cursor-pointer text-3xl font-semibold text-black hover:text-pink hover:underline"
            >
              Histórias de sucesso
            </a>
          </li>
          <li>
            <a
              href="#perguntas-frequentes"
              onClick={handleaClick}
              className="cursor-pointer text-3xl font-semibold text-black hover:text-pink hover:underline"
            >
              Perguntas frequentes
            </a>
          </li>
          <li>
            <a
              href="#contato"
              onClick={handleaClick}
              className="cursor-pointer text-3xl font-semibold text-black hover:text-pink hover:underline"
            >
              Contato
            </a>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default Navigation;
