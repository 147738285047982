import React from "react";
import whatsappIcon from "assets/icons/whatsapp.svg";

function Contact() {
  return (
    <section className="flex w-full flex-col items-center justify-center bg-[#AD57B6] py-8">
      <div className="my-10 flex w-11/12 flex-col items-center justify-center gap-4 md:my-20 md:flex-row">
        <div className="flex flex-col items-center justify-center gap-4">
          <header className="text-2xl font-semibold md:text-5xl">
            Atendimento Personalizado
          </header>

          <p className="mb-6 text-center text-xl md:text-2xl">
            Restaram dúvidas sobre como começar sua jornada como revendedora
            Fani Semijoias? Estamos aqui para ajudar!
          </p>

          <a
            href="https://api.whatsapp.com/send?phone=5543920017714&text=Ol%C3%A1.%20Vim%20pelo%20site%20e%20quero%20de%20ser%20uma%20revendedora%20os%20Fani."
            target="_blank"
          >
            <span className="rounded-full border-2 border-black p-4 px-6 text-lg font-medium transition-colors duration-300 hover:opacity-75 md:text-2xl">
              Entre em contato
            </span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contact;
