import React from "react";

function About() {
  return (
    <section
      id="quem-somos"
      className="flex min-h-screen items-center justify-center bg-white"
    >
      <div className="flex w-11/12 flex-col justify-center gap-8 md:w-9/12">
        <header className="text-center text-4xl md:text-5xl">Quem Somos</header>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <header className="text-3xl font-medium md:text-4xl">Missão</header>
            <p className="text-justify text-xl md:text-3xl">
              Proporcionar oportunidades excepcionais para que mulheres realizem
              seus sonhos revendendo Fani Semijoias.
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <header className="text-3xl font-medium md:text-4xl">Visão</header>
            <p className="text-justify text-xl md:text-3xl">
              Ser a empresa líder no mercado de revenda de semijoias no Vale do
              Ivaí, oferecendo as melhores oportunidades de negócios para
              mulheres empreendedoras.
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <header className="text-3xl font-medium md:text-4xl">
              Valores
            </header>
            <p className="text-justify text-xl md:text-3xl">
              Agir com honestidade em todas as nossas negociações, capacitando o
              empoderamento de mulheres de todas as origens, levando-as a
              alcançar sua independência financeira com semijoias
              de alta qualidade.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
