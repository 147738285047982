import React from "react";
import instagram from "assets/icons/instagram.svg";
import facebook from "assets/icons/facebook.svg";
import tiktok from "assets/icons/tiktok.svg";
import youtube from "assets/icons/youtube.svg";

function Footer() {
  return (
    <footer
      id="contato"
      className="flex min-h-96 items-center justify-center bg-black"
    >
      <div className="m-4 flex w-11/12 flex-col justify-between gap-4 text-white md:m-8 md:gap-20 md:text-lg">
        <article className="flex w-full flex-col justify-between gap-4 md:flex-row">
          <div className="flex flex-1 flex-col justify-start gap-4">
            <header className="text-3xl md:text-5xl">
              Vamos trabalhar juntas?
            </header>
            <p className="w-full text-justify md:w-10/12 md:text-xl">
              Que bom que você chegou até aqui! Faça seu cadastro agora e
              garanta a oportunidade de conquistar a independência financeira
              que você sempre sonhou!
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSenTLRltBSYYK6-7Ssnt0UeCkrYNcYRBDXwAlAsZ5Lpso2qWw/viewform"
              target="_blank"
              className="rounded-full border-2 border-white p-3 text-center text-xl font-medium hover:opacity-75 md:w-1/3"
            >
              Cadastre-se
            </a>
          </div>
          <ul className="flex flex-1 items-center justify-start gap-4 md:justify-end">
            <li className="cursor-pointer hover:opacity-75">
              <a href="https://www.instagram.com/fa.joias/" target="_blank">
                <img
                  src={instagram}
                  alt="Instagram"
                  className="h-24 w-14 md:h-16 md:w-16"
                />
              </a>
            </li>
            <li className="cursor-pointer hover:opacity-75">
              <a
                href="https://www.facebook.com/profile.php?id=61556485764656"
                target="_blank"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  className="h-24 w-14 md:h-16 md:w-16"
                />
              </a>
            </li>
            <li className="cursor-pointer hover:opacity-75">
              <a href="https://www.tiktok.com/@fasemijoias/" target="_blank">
                <img
                  src={tiktok}
                  alt="TikTok"
                  className="h-24 w-14 md:h-16 md:w-16"
                />
              </a>
            </li>
          </ul>
        </article>

        <article className="flex w-full flex-col-reverse justify-between gap-4 md:flex-row">
          <div className="flex flex-col gap-1">
            <a
              className="text-xs uppercase md:text-lg"
              href="https://dorac.com.br"
              target="_blank"
            >
              &copy; Fanisemijoias&reg;. Desenvolvido por{" "}
              <span className="underline">agência dorac</span>
            </a>
            <a className="text-xs uppercase underline md:text-lg" href="#">
              Política de privacidade
            </a>
          </div>

          <div className="hidden flex-col items-center gap-4 md:flex md:flex-row">
            <a
              className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-full border-2 border-white bg-transparent px-6 py-4 text-lg hover:opacity-75 md:text-2xl"
              href="https://api.whatsapp.com/send?phone=5543920017714&text=Ol%C3%A1.%20Vim%20pelo%20site%20e%20quero%20de%20ser%20uma%20revendedora%20os%20Fani."
              target="_blank"
            >
              <span>(43) 92001-7714</span>
            </a>
            <a
              className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-full border-2 border-white bg-transparent px-6 py-4 text-lg hover:opacity-75 md:text-2xl"
              href="mailto:semijoias.fan@gmail.com"
              target="_blank"
            >
              <span>semijoias.fan@gmail.com</span>
            </a>
          </div>
        </article>
      </div>
    </footer>
  );
}

export default Footer;
