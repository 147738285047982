import React from "react";

function ResellerRequirements() {
  return (
    <section className="flex min-h-96 w-full items-center justify-center bg-gray-100">
      <div className="m-4 flex w-11/12 flex-col gap-8 md:m-8 md:w-9/12">
        <header className="text-4xl md:text-5xl">
          Requisitos para ser uma revendedora Fani Semijoias
        </header>

        <hr className="border-2 border-black" />

        <div>
          <ul className="grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-12">
            <li className="flex flex-col gap-4">
              <header className="text-2xl font-medium md:text-3xl">
                Ser maior de Idade
              </header>
              <p className="text-justify text-lg md:text-xl">
                É necessário ser maior de idade para se tornar uma revendedora
                Fani Semijoias, garantindo assim, legalidade ao contrato e
                responsabilidades nas decisões relacionadas ao negócio.
              </p>
            </li>
            <li className="flex flex-col gap-4">
              <header className="text-2xl font-medium md:text-3xl">
                Possuir um CPF válido
              </header>
              <p className="text-justify text-lg md:text-xl">
                É essencial ter um CPF válido e estar regularizado para iniciar
                sua jornada como revendedora Fani, facilitando o processo legal.
              </p>
            </li>
            <li className="flex flex-col gap-4">
              <header className="text-2xl font-medium md:text-3xl">
                Vontade de evoluir
              </header>
              <p className="text-justify text-lg md:text-xl">
                Ter disposição para aprender, crescer e evoluir é fundamental
                para o sucesso como revendedora Fani, abrindo-se para novas
                oportunidades e desenvolvimento pessoal.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ResellerRequirements;
