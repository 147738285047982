import React from "react";
import collectionImage1 from "assets/images/collection1.png";
import collectionImage2 from "assets/images/collection2.png";
import collectionImage3 from "assets/images/collection3.jpg";
import collectionImage4 from "assets/images/collection4.png";
import collectionImage5 from "assets/images/collection5.png";

function OurCollection() {
  return (
    <section
      id="nossa-colecao"
      className="flex w-full items-center justify-center"
    >
      <div className="grid grid-cols-2 gap-1 md:grid-cols-5">
        <picture className="group relative cursor-pointer">
          <img
            src={collectionImage1}
            alt="collection"
            loading="lazy"
            className="h-auto w-full"
          />
          {/* <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 transition-opacity duration-1000 ease-in-out group-hover:bg-opacity-75'>
            <span className='text-center text-xl font-medium text-white opacity-0 transition-opacity duration-1000 ease-in-out group-hover:opacity-100 md:text-2xl'>
            Texto da Coleção 1
            </span>
            </div> */}
        </picture>

        <picture className="group relative cursor-pointer">
          <img
            src={collectionImage2}
            alt="collection"
            loading="lazy"
            className="h-auto w-full"
          />
          {/* <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 transition-opacity duration-1000 group-hover:bg-opacity-75'>
            <span className='text-center text-xl text-white opacity-0 transition-opacity duration-1000 ease-in-out group-hover:opacity-100 md:text-2xl'>
              Texto da Coleção 2
            </span>
          </div> */}
        </picture>

        <picture className="group relative cursor-pointer">
          <img
            src={collectionImage3}
            alt="collection"
            loading="lazy"
            className="h-auto w-full"
          />
          {/* <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 transition-opacity duration-1000 group-hover:bg-opacity-75'>
            <span className='text-center text-xl text-white opacity-0 transition-opacity duration-1000 ease-in-out group-hover:opacity-100 md:text-2xl'>
            Texto da Coleção 3
            </span>
            </div> */}
        </picture>

        <picture className="group relative cursor-pointer">
          <img
            src={collectionImage4}
            alt="collection"
            loading="lazy"
            className="h-auto w-full"
          />
          {/* <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 transition-opacity duration-1000 group-hover:bg-opacity-75'>
            <span className='text-center text-xl text-white opacity-0 transition-opacity duration-1000 ease-in-out group-hover:opacity-100 md:text-2xl'>
              Texto da Coleção 4
            </span>
          </div> */}
        </picture>

        <picture className="group relative hidden cursor-pointer md:block">
          <img
            src={collectionImage5}
            alt="collection"
            loading="lazy"
            className="h-auto w-full"
          />
          {/* <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 transition-opacity duration-1000 group-hover:bg-opacity-75'>
            <span className='text-center text-xl text-white opacity-0 transition-opacity duration-1000 ease-in-out group-hover:opacity-100 md:text-2xl'>
            Texto da Coleção 5
            </span>
            </div> */}
        </picture>
      </div>
    </section>
  );
}

export default OurCollection;
