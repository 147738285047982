import React from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper/modules";

import slide1 from "assets/images/fanisemijoias.png";

export default function App() {
  return (
    <section id="home" className="flex w-full items-center justify-center">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        pagination={{ clickable: true }}
        modules={[EffectFade, Pagination]}
        autoplay={{ delay: 5000 }}
        className="mySwiper w-full md:min-h-screen"
      >
        <SwiperSlide>
          <img src={slide1} loading="lazy" className="w-full" />
        </SwiperSlide>
      </Swiper>
    </section>
  );
}
