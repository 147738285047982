import React from "react";

function Faq() {
  return (
    <section
      id="perguntas-frequentes"
      className="flex min-h-screen w-full items-center justify-center"
    >
      <div className="m-4 flex w-11/12 flex-col gap-8 md:m-8 md:w-9/12">
        <header className="text-center text-4xl md:text-justify md:text-5xl">
          Perguntas Frequentes
        </header>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
          <div className="flex flex-col gap-4 rounded border border-gray-300 p-4">
            <h2 className="text-xl font-medium md:text-2xl">
              Como faço para me tornar uma revendedora?
            </h2>
            <p className="text-base md:text-lg">
              Para se tornar uma revendedora, você precisa se cadastrar em nosso
              site e seguir os passos indicados na seção de cadastro. Após isso,
              entraremos em contato para orientá-la sobre os próximos passos.
            </p>
          </div>
          <div className="flex flex-col gap-4 rounded border border-gray-300 p-4">
            <h2 className="text-xl font-medium md:text-2xl">
              Quais são os benefícios de revender os produtos Fani Semijoias?
            </h2>
            <p className="text-base md:text-lg">
              Ao revender os produtos Fani Semijoias, você terá acesso a
              comissões competitivas, treinamento especializado, suporte
              contínuo da equipe e a oportunidade de trabalhar com produtos de
              alta qualidade reconhecidos no mercado.
            </p>
          </div>
          <div className="rounded-lg border border-gray-300 p-6">
            <h2 className="mb-3 text-xl font-medium md:text-2xl">
              É necessario algum investimento inicial?
            </h2>
            <p className="text-base md:text-lg">
              Nossos produtos são consignados, o que significa que você não
              precisa se preocupar com investimentos iniciais. Você pode começar
              sem custo.
            </p>
          </div>
          <div className="flex flex-col gap-4 rounded border border-gray-300 p-4">
            <h2 className="text-xl font-medium md:text-2xl">
              Qual é a garantia e suporte para produtos com defeitos?
            </h2>
            <p className="text-base md:text-lg">
              Oferecemos garantia para todos os nossos produtos. Caso algum
              produto apresente defeito, você pode entrar em contato com nosso
              suporte para orientações sobre a troca ou devolução. Estamos aqui
              para garantir a sua satisfação e a de seus clientes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
