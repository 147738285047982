import React, { useEffect } from "react";
import Header from "components/Header";
import Carousel from "./components/Carousel";
import About from "./components/About";
import ResellerProgram from "./components/ResellerProgram";
import ResellerRequirements from "./components/ResellerRequirements";
import OurCollection from "./components/OurCollection";
import InstagramTestimonials from "./components/InstagramTestimonials";
import Faq from "./components/Faq";
import Contact from "./components/Contact";
import Footer from "components/Footer";
import Whatsapp from "components/Whatsapp";

function Home() {
  useEffect(() => {
    document.title = "Fani Semijoias";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Carousel />
      <About />
      <ResellerProgram />
      <OurCollection />
      <ResellerRequirements />
      <InstagramTestimonials />
      <Faq />
      <Contact />
      <Footer />
      <Whatsapp />
    </>
  );
}

export default Home;
